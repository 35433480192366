import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/layout'
import { css, Global } from '@emotion/react'
import { textStyles } from '../styles/texts'
import colors, { shadows } from '../styles/colors'
import { maxContentFrameWidthForPage, spacing, spacing2XL, spacingL, spacingS } from '../styles/variables'
import styled from '@emotion/styled'
import SEO from '../components/seo'

const Container = styled('div')`
	padding: ${spacingL};
	max-width: ${maxContentFrameWidthForPage};
  margin: auto;
`;

const styles = css`
	* {
		${textStyles.lRegular};
	}
	h1 {
		${textStyles.xxxlMedium}
		color: ${colors.textPrimary}
	}
	h2 {
		${textStyles.xxlMedium}
		color: ${colors.textPrimary};
		margin: ${spacing} 0;
	}
	h3 {
		${textStyles.xlMedium}
		color: ${colors.textPrimary};
		margin: ${spacing} 0;
	}
	p {
		${textStyles.lRegular};
		color: ${colors.textBlack};
		margin: ${spacing} 0;
	}
	main ul > li, main ol > li {
		margin: ${spacingS} ${spacingS} ${spacingS} ${spacing};
		p {
			margin: 0;
		}
	}


	strong {
		font-weight: bold;
	}

	//Only applies to .mdx files
	.gatsby-resp-image-wrapper {
		margin: ${spacing} 0;
		${shadows.down};
		max-width: 400px !important;
	}
`;

const Page = (props) => {

	const data = props.data
	// console.log(props, data)
	// const image = getImage(data.mdx.frontmatter.hero_image)

	return (
		<Layout>
			<Global
				styles={styles}
			/>
			<SEO title={data.mdx.frontmatter.title} />
			<Container>
				{/* <h1>{data.mdx.frontmatter.title}</h1> */}
				<MDXRenderer>
					{data.mdx.body}
				</MDXRenderer>
			</Container>
		</Layout>
	)
}

export const query = graphql`
query ($slug: String) {
  mdx(slug: {eq: $slug}) {
    id
    slug
      body
      frontmatter {
        title
      }
  }
}
`

export default Page